import { RootState } from "../";

export const fromSavedFilters = (state: RootState) => state.savedFilters;

export const predefinedFiltersSelector = (state: RootState) =>
  fromSavedFilters(state).predefinedFilters;

export const getPredefinedFiltersSuccessfulSelector = (state: RootState) =>
  fromSavedFilters(state).fetchedGetPredefinedFilters;
export const getPredefinedFiltersPendingSelector = (state: RootState) =>
  fromSavedFilters(state).fetchingGetPredefinedFilters;
export const getPredefinedFiltersFailedSelector = (state: RootState) =>
  fromSavedFilters(state).fetchedGetPredefinedFiltersFail;

export const postSavedFilterIdSelector = (state: RootState) =>
  fromSavedFilters(state).postSavedFilterId;
export const postSavedFilterPendingSelector = (state: RootState) =>
  fromSavedFilters(state).fetchingPostSavedFilter;
export const postSavedFilterSuccessfulSelector = (state: RootState) =>
  fromSavedFilters(state).fetchedPostSavedFilter;
export const postSavedFilterFailedSelector = (state: RootState) =>
  fromSavedFilters(state).fetchedPostSavedFilterFail;

export const deleteSavedFilterSuccessfulSelector = (state: RootState) =>
  fromSavedFilters(state).fetchedDeleteSavedFilter;
export const deleteSavedFilterFailedSelector = (state: RootState) =>
  fromSavedFilters(state).fetchedDeleteSavedFilterFail;

export const savedFiltersOverWriteSelector = (state: RootState) =>
  fromSavedFilters(state).isOverWrite;

export const savedFiltersHasAlertSelector = (state: RootState) => fromSavedFilters(state).hasAlerts;
