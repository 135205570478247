import React, { MouseEvent } from "react";

import { ChipAction } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./ChipList.module.less";

import {
  transformOperatorToChip,
  InputChipQueryOperatorsEnum,
} from "../AppFilter/inputChip/InputChipQueryConfig";
import { IChipListActionProps } from "./ChipList";
import { NESTING_COMMAND_KEYS } from "../AppFilter/inputChip/InputChipConfig";
import { ChipType as ChipConfigType } from "@bbdevcrew/bb_ui_kit_fe";

const ChipType: { [key: string]: ChipConfigType } = {
  [InputChipQueryOperatorsEnum.OR as string]: "default" as ChipConfigType,
  [InputChipQueryOperatorsEnum.AND as string]: "success" as ChipConfigType,
  [InputChipQueryOperatorsEnum.AND_NOT as string]: "error" as ChipConfigType,
  [InputChipQueryOperatorsEnum.OR_NOT as string]: "error" as ChipConfigType,
  [InputChipQueryOperatorsEnum.NOT as string]: "error" as ChipConfigType,
};

export const ChipListQuery = React.memo(function ({
  _size,
  value,
  renderAddChip,
  onActionClick,
}: IChipListActionProps) {
  return (
    <div className={s.bbChipListContainer}>
      {value.map((chip, idx) => {
        return NESTING_COMMAND_KEYS.includes(chip) ? (
          <span key={idx}>{chip}</span>
        ) : (
          <ChipAction
            key={idx}
            _size={_size}
            text={transformOperatorToChip(chip)}
            wrapperClassName={s.bbChipListAction}
            _type={ChipType[chip as keyof typeof InputChipQueryOperatorsEnum]}
            onActionClick={(event: MouseEvent<HTMLButtonElement>) => {
              // Could be too implicit. Remove if event needs to be bubbled up.
              event.preventDefault();
              return onActionClick(chip, idx);
            }}
          />
        );
      })}
      {renderAddChip && renderAddChip()}
    </div>
  );
});

ChipListQuery.displayName = "ChipListQuery";
