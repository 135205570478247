import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames/bind";

import Timeline from "@components/_common/TimelineChart";
import CommentBreakdown from "@components/insights/search/commentBreakdown/CommentBreakdown";
import Aggregations from "@components/insights/search/aggregations/Aggregations";
import SentimentChart from "@components/insights/search/sentimentChart/SentimentChart";

import reportStyles from "./../PDFReport.module.less";
import s from "./SentimentDetails.module.less";

import { ISentimentDetailsProps } from "./SentimentDetails.types";
import { IAreaChartData, IPieChartUnit } from "@components/insights/search/Search.type";
import {
  getLines,
  normalizeSentimentData,
  normalizeTimelineData,
  sentimentColorData,
} from "@components/listen/sentimentDetails/SentimentDetails.helpers";
import { useTiktokEnabled } from "@utils/useTiktokEnabled";

export const SentimentDetails: React.FC<ISentimentDetailsProps> = ({
  title,
  data,
  isFetching,
  filters,
  className,
}) => {
  const isTiktokEnabled = useTiktokEnabled();

  const [commentSentimentData, setCommentSentimentData] = useState<IPieChartUnit[]>([]);
  const [commentTimelineData, setCommentTimelineData] = useState<IAreaChartData[]>([]);
  const [commentSentimentTotal, setTotal] = useState<IPieChartUnit>({} as IPieChartUnit);

  const lines = useMemo(() => {
    return isTiktokEnabled
      ? getLines()
      : getLines().filter(line => line.dataKey !== "not_applicable");
  }, [isTiktokEnabled]);
  useEffect(() => {
    if (!isFetching && data) {
      const commentStatData = normalizeSentimentData(data);
      const pieChartTotal = commentStatData.find(unit => unit.rawName === "total");
      const pieChartData = commentStatData.filter(unit => unit.rawName !== "total");

      if (pieChartTotal) {
        setTotal(pieChartTotal);
        setCommentSentimentData(pieChartData);
      }
      if (data) {
        const timelineData = normalizeTimelineData(data);
        setCommentTimelineData(timelineData);
      }
    }
  }, [isFetching, data]);
  return (
    <div className={reportStyles.bbPDFReportSection}>
      {title && (
        <h2 className={classNames(reportStyles.bbPDFReportSectionTitle, className)}>{title}</h2>
      )}
      <div className={s.bbSentimentDetails}>
        <div className={s.bbSentimentDetailsChart}>
          <SentimentChart
            isLoading={isFetching}
            data={commentSentimentData}
            total={commentSentimentTotal}
            filterToSpecificItem={() => {}} // eslint-disable-line
          />
        </div>
        <div className={s.bbSentimentDetailsTimeline}>
          <Timeline
            filters={filters}
            data={commentTimelineData}
            filterToSpecificItem={() => {}} // eslint-disable-line
            lines={lines}
          />
        </div>
      </div>
      <div className={s.bbSentimentDetails}>
        <div className={s.bbSentimentDetailsChart}>
          <Aggregations
            isLoading={isFetching}
            data={data || null}
            widthInfo={156}
            filters={filters}
            colors={sentimentColorData}
          />
        </div>
        <div className={s.bbSentimentDetailsBreakdown}>
          <CommentBreakdown
            appFilters={filters}
            filterToSpecificItem={() => {}} // eslint-disable-line
            data={data?.breakdowns || []}
          />
        </div>
      </div>
    </div>
  );
};
