import React from "react";
import classNames from "classnames/bind";

import AnalysisTrends from "@components/insights/overview/analysisTrends/AnalysisTrends";
import MostUsedEmojis from "@components/insights/overview/mostUsedEmojis/MostUsedEmojis";
import TopTags from "@components/listen/topics/topTags/TopTags";

import reportStyles from "./../PDFReport.module.less";
import s from "./Topics.module.less";

import { ITopicsProps } from "./Topics.types";

export const Topics: React.FC<ITopicsProps> = ({ title, data, isFetching, filters, className }) => {
  return (
    <div className={reportStyles.bbPDFReportSection}>
      {title && (
        <h2 className={classNames(reportStyles.bbPDFReportSectionTitle, className)}>{title}</h2>
      )}
      <div className={s.bbTopicsRow}>
        <div className={s.bbTopicsTrends}>
          <AnalysisTrends
            loading={isFetching}
            wordCloudData={data.workCloudData}
            keywordPhrasesData={data.keywordPhrasesData}
            filters={filters}
          />
        </div>
        <div className={s.bbTopicsTrends}>
          <MostUsedEmojis filters={filters} loading={isFetching} data={data.emojis} />
        </div>
      </div>
      <div className={s.bbTopicsRow}>
        <TopTags data={data.tags} loading={isFetching} />
      </div>
    </div>
  );
};
