import classNames from "classnames";
import { Layout, Grid } from "antd";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import AppHeader from "../AppHeader/AppHeader";
import AppFooter from "../Appfooter/AppFooter";
import AppSidebar from "../AppSidebar/AppSidebar";
import AppHeaderMobile from "../AppHeader/AppHeaderMobile";
import AppFiltersContainer from "@containers/AppFiltersContainer";
import AIInsightsModal from "../AIInsightsModal";

import { clientDataSelector, meSelector } from "@store/me/selectors";
import { predefinedFiltersSelector } from "@store/savedFilters/selectors";
import { savedFilterValueSelector } from "@store/filters/selectors";
import { saveFilterAction, triggerFilteringWithoutUrlAction } from "@store/filters/actions";
import { useAppFilters } from "@utils/appFilters";
import { useBatchMode } from "@containers/BatchMode/BatchMode";
import { getDefaultFilterValue } from "@utils/filters";
import { removeItemUrlSearch } from "@utils/removeItemUrlSearch";
import { getDefaultFiltersConfig } from "../AppFilter/config";
import { FilterContext } from "./FilterContext";
import { getCurrentModuleName } from "@utils/paths";
import { getSubFeatureName } from "@utils/feature";
import { useCurrentFilters } from "@utils/useCurrentFilters";
import { selectItem } from "../AppHeader/AppHeaderCommons";
import { AppSidebarProvider } from "../AppSidebar/helpers";

import s from "./AppLayout.module.less";

import { IFilters } from "@store/filters/types";
import { ISavedFilter } from "../AppFilter/AppFilters.type";
import { IFormHandler } from "../AppFilter/Filters.type";
import { IAppLayout } from "./AppLayout.types";
import { isPostFilterAllowed } from "@components/insights/posts/postsTable/helpers";

const { Content } = Layout;

const AppLayout: FC<IAppLayout> = ({
  menu = [],
  extra,
  children,
  basename,
  hasHeader,
  hasFooter,
  avatarUrl,
  selectedKey,
  defaultSelectedKey,
  contentClassName,
  onPreRedirect = () => Promise.resolve(),
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const screens = Grid.useBreakpoint();
  const [searchParams] = useSearchParams();
  const FiltersRef = useRef<IFormHandler>(null);
  const { isBatchModeActive } = useBatchMode();
  const filters = useCurrentFilters();

  const subFeatureName = getSubFeatureName();
  const { updateSearchDataAndUrl } = useAppFilters();
  const { content, beforeContent, afterContent } = children;
  const hasNewSidenav = ["listen", "insights", "care_cps"].includes(getCurrentModuleName());
  const isFooterFixed = window.location.pathname.includes("/settings/asset-management/add-assets");
  const isMobile = !screens.md;
  const isAIInsightsAvailable =
    getCurrentModuleName() === "insights" &&
    (searchParams.get("section") === "listen-overview" ||
      searchParams.get("section") === "listen-sentiment_details" ||
      searchParams.get("section") === "topics");

  const [stickyFilterValue, setStickyFilterValue] = useState<string | null>(null);

  const me = useSelector(meSelector);
  const clientData = useSelector(clientDataSelector);

  const savedFilterValue = useSelector(savedFilterValueSelector);
  const predefinedFilters = useSelector(predefinedFiltersSelector);

  const triggerFilterClick = useCallback(
    (data: IFilters) => dispatch(triggerFilteringWithoutUrlAction(data)),
    [dispatch],
  );

  const setSavedFilterValue = useCallback(
    (id: string | number | null) => dispatch(saveFilterAction(id)),
    [dispatch],
  );

  const getDefaultFilters = useCallback(
    () => getDefaultFilterValue(me),
    // eslint-disable-next-line
    [clientData],
  );

  const props = {
    basename: basename || "",
    selectedKey: selectedKey || "",
    defaultSelectedKey,
    screens,
    menu,
    extra,
    avatarUrl,
    hideSubMenu: hasNewSidenav,
    onPreRedirect,
  };

  const hasSavedFilters = useMemo(
    () =>
      hasNewSidenav &&
      searchParams.get("section") !== "report-compare" &&
      searchParams.get("section") !== "listen_more-listening_sources" &&
      searchParams.get("section") !== "listen_more-trending_hashtags" &&
      getCurrentModuleName() !== "care_cps",
    [hasNewSidenav, searchParams],
  );

  const hasFilters = useMemo(() => {
    return (
      searchParams.get("section") !== "report-compare" &&
      searchParams.get("section") !== "listen_more-listening_sources" &&
      searchParams.get("section") !== "listen_more-trending_hashtags" &&
      (["insights", "care_cps"].includes(getCurrentModuleName()) ||
        ["dashboard", "inbox", "smart-inbox", "transparency"].includes(subFeatureName))
    );
  }, [searchParams, subFeatureName]);

  useEffect(() => {
    const savedFilterSearchParam = searchParams.get("saved_filter");

    if (savedFilterSearchParam && !savedFilterValue) setSavedFilterValue(savedFilterSearchParam);
  }, [searchParams, savedFilterValue, setSavedFilterValue]);

  const getCustomFilters = useCallback(() => {
    const defaultFilters = getDefaultFiltersConfig(t, me);

    if (searchParams.get("section") === "report-posts") {
      return defaultFilters
        .filter(({ name, type }) => isPostFilterAllowed(type, name))
        .map(filter => {
          if (filter.name === "data_range_option") {
            return {
              ...filter,
              label: t("components:posts:filters:date:label"),
              tooltip: t("components:posts:table:tableHeader:tooltips:post"),
            };
          }
          return filter;
        });
    }
    return defaultFilters;
  }, [searchParams, t, me]);

  const onFilterInternal = useCallback(
    (data: IFilters, param?: string | ISavedFilter | undefined) => {
      if (searchParams.get("section") === "report-posts" || subFeatureName === "transparency") {
        triggerFilterClick(data);
      }

      updateSearchDataAndUrl(data, param);
    },
    [subFeatureName, searchParams, triggerFilterClick, updateSearchDataAndUrl],
  );

  const onSavedStickyChange = useCallback(
    (filterId?: string | number) => {
      const savedFilters = predefinedFilters.find(({ id }) => id === "savedFilters");
      const stickyFilters = predefinedFilters.find(({ id }) => id === "stickyFilters");

      const savedFilter = savedFilters?.items.find(
        ({ id: savedFilterId }) => savedFilterId === Number(filterId),
      );
      const stickyFilter = stickyFilters?.items.find(
        ({ id: stickyFilterId }) => stickyFilterId === filterId,
      );

      const filter = savedFilter || stickyFilter || null;
      const param = savedFilter ? ({ saved_filter: filterId } as ISavedFilter) : "id";

      onFilterInternal(filter?.request || getDefaultFilters(), param);
    },
    [predefinedFilters, onFilterInternal, getDefaultFilters],
  );

  const onResetFilters = useCallback(() => {
    setSavedFilterValue(null);
    setStickyFilterValue(null);
    onSavedStickyChange("");
    FiltersRef.current?.resetFields();
    FiltersRef.current?.initializeFormValues(getDefaultFilters());
    removeItemUrlSearch("id");
    removeItemUrlSearch("saved_filter");
  }, [setSavedFilterValue, setStickyFilterValue, getDefaultFilters, onSavedStickyChange]);

  const onSavedFilterChange = useCallback(
    (id: string | number | null) => {
      const validId = id === "null" ? null : id;

      if (validId) onSavedStickyChange(validId);

      if (validId === null) onResetFilters();
      else setSavedFilterValue(validId);
    },
    [onSavedStickyChange, onResetFilters, setSavedFilterValue],
  );

  const filterContextValue = {
    clientData,
    filters,
    hasNewSidenav,
    stickyFilterValue,
    onFilterInternal,
    onResetFilters,
    customFilters: getCustomFilters(),
    onSavedStickyChange,
    onSavedFilterChange,
    setStickyFilterValue,
    isBatchModeActive,
  };

  const appHeader = !screens.lg ? <AppHeaderMobile {...props} /> : <AppHeader {...props} />;

  return (
    <FilterContext.Provider value={filterContextValue}>
      <AppSidebarProvider>
        <Layout>
          {(hasHeader || hasHeader === undefined) && appHeader}
          <div className={s.bbAppLayoutContentWrapper}>
            {hasNewSidenav && (
              <AppSidebar
                hasSavedFilters={hasSavedFilters}
                onSavedFilterChange={onSavedFilterChange}
              />
            )}
            <Content
              id="layout-content"
              className={classNames(s.bbLayoutContent, {
                [s.bbLayoutContentSmall]:
                  selectedKey && selectItem(menu, selectedKey) ? false : true,
                [s.bbLayoutContentFull]: hasFooter === false,
              })}
            >
              <div className={contentClassName}>
                {beforeContent}
                {content}
                {afterContent}
                {hasFilters && isMobile && <AppFiltersContainer ref={FiltersRef} />}
              </div>
            </Content>
            {hasFilters && !isMobile && <AppFiltersContainer ref={FiltersRef} />}
            {isAIInsightsAvailable && <AIInsightsModal />}
          </div>
          {(hasFooter || hasFooter === undefined) && <AppFooter isFixed={isFooterFixed} />}
        </Layout>
      </AppSidebarProvider>
    </FilterContext.Provider>
  );
};

export default AppLayout;
