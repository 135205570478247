import { ajax } from "rxjs/ajax";
import { Observable } from "rxjs";
import { StateObservable } from "redux-observable";
import { catchError, filter, map, retry, switchMap, takeUntil } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";

import { RootState } from "../";
import * as actions from "./actions";
import { threads, threadsMessages, threadsMessagesResponses } from "@utils/paths";
import { handleError } from "@utils/apiErrorHandler";
import { getHeaders } from "@utils/headers";
import {
  IGetMessagesResponseSuccessPayload,
  IGetThreadMessagesPayload,
  IPostThreadsPayload,
  ISendThreadMessagesSuccessPayload,
  ThreadsActions,
} from "./types";

const RESPONSE_STATUS_CHECK_INTERVAL = 5000;
const RESPONSE_STATUS_CHECK_RETRIES = 10;

export const postThreads = (
  action$: Observable<ThreadsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.fetchThreadsAction)),
    switchMap(a =>
      ajax
        .post<IPostThreadsPayload>(
          threads,
          { search_id: a.payload },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          switchMap(data => [
            actions.fetchThreadsSuccess(data),
            actions.fetchThreadMessagesAction(data.created_thread.id),
          ]),
          catchError(err => handleError(err, actions.fetchThreadsFailure)),
        ),
    ),
  );

export const getListMessages = (
  action$: Observable<ThreadsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.fetchThreadMessagesAction)),
    switchMap(a =>
      ajax
        .get<IGetThreadMessagesPayload>(
          threadsMessages(a.payload),
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          switchMap(data => {
            if (data.message_id_waiting_for_response) {
              return [
                actions.fetchThreadMessagesSuccess(data),
                actions.fetchAIResponseAction({
                  id: a.payload,
                  messageId: data.message_id_waiting_for_response,
                }),
              ];
            }

            return [actions.fetchThreadMessagesSuccess(data)];
          }),
          catchError(err => handleError(err, actions.fetchThreadMessagesFailure)),
        ),
    ),
  );

export const sendMessage = (
  action$: Observable<ThreadsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.sendThreadMessageAction)),
    switchMap(a =>
      ajax
        .post<ISendThreadMessagesSuccessPayload>(
          threadsMessages(a.payload.id),
          { message: a.payload.message },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          switchMap(data => [
            actions.sendThreadMessageSuccess(data),
            actions.fetchAIResponseAction({ id: a.payload.id, messageId: data.created_message.id }),
          ]),
          catchError(err => handleError(err, actions.sendThreadMessageFailure)),
        ),
    ),
  );

export const getResponse = (
  action$: Observable<ThreadsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.fetchAIResponseAction)),
    switchMap(a =>
      ajax
        .get<IGetMessagesResponseSuccessPayload>(
          threadsMessagesResponses(a.payload.id, a.payload.messageId),
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          retry({
            count: RESPONSE_STATUS_CHECK_RETRIES,
            delay: RESPONSE_STATUS_CHECK_INTERVAL,
            resetOnSuccess: true,
          }),
          map(e => e.response),
          map(data => actions.fetchAIResponseSuccess(data)),
          catchError(err => handleError(err, actions.fetchAIResponseFailure)),
          takeUntil(state$.pipe(filter(state => !state.aiInsights.open))),
        ),
    ),
  );
