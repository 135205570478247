export const TOGGLE_OPEN_AI_MODAL = "AI/TOGGLE_OPEN_AI_MODAL";

export const FETCH_THREADS = "AI/FETCH_THREADS";
export const FETCH_THREADS_SUCCESS = "AI/FETCH_THREADS_SUCCESS";
export const FETCH_THREADS_FAILURE = "AI/FETCH_THREADS_FAILURE";

export const FETCH_THREAD_MESSAGES = "AI/FETCH_THREAD_MESSAGES";
export const FETCH_THREAD_MESSAGES_SUCCESS = "AI/FETCH_THREAD_MESSAGES_SUCCESS";
export const FETCH_THREAD_MESSAGES_FAILURE = "AI/FETCH_THREAD_MESSAGES_FAILURE";

export const SEND_THREAD_MESSAGE = "AI/SEND_THREAD_MESSAGE";
export const SEND_THREAD_MESSAGE_SUCCESS = "AI/SEND_THREAD_MESSAGE_SUCCESS";
export const SEND_THREAD_MESSAGE_FAILURE = "AI/SEND_THREAD_MESSAGE_FAILURE";

export const FETCH_AI_RESPONSE = "AI/FETCH_AI_RESPONSE";
export const FETCH_AI_RESPONSE_SUCCESS = "AI/FETCH_AI_RESPONSE_SUCCESS";
export const FETCH_AI_RESPONSE_FAILURE = "AI/FETCH_AI_RESPONSE_FAILURE";
