import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { Modal } from "@bbdevcrew/bb_ui_kit_fe";
import { SteamsList } from "./StreamsList";

import { setCareCpsStreamAction } from "@store/streams/actions";

import { IStreamsModalProps } from "./StreamsModal.types";
import { ICareCPSStream } from "@store/streams/types";

export const StreamsModal: React.FC<IStreamsModalProps> = ({
  view,
  setView,
  close,
  ...restProps
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onStreamSelect = useCallback(
    (stream: ICareCPSStream) => {
      dispatch(setCareCpsStreamAction(stream));
      close();
    },
    [close, dispatch],
  );

  const modalProps = {
    list: {
      ...restProps,
      hideFooter: true,
      hideHeader: true,
      hideCloseIcon: true,
    },
    save: {},
    edit: {},
  };

  return (
    <Modal
      open={!!view}
      width={600}
      noPadding
      title={t("generic:streams")}
      onCancel={close}
      hideHeader
      {...(view ? modalProps[view] : { hideFooter: true, hideHeader: true, hideCloseIcon: true })}
    >
      <>
        {view === "list" && (
          <SteamsList onStreamSelect={onStreamSelect} onAddClick={() => setView("save")} />
        )}
      </>
    </Modal>
  );
};
