import React, { FC, HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { Empty } from "antd";
import { Card, Loading, PieChart } from "@bbdevcrew/bb_ui_kit_fe";

import { CHART_WIDTH, CHART_HEIGHT } from "../helpers";
import { FilterFunctionType, IPieChartUnit } from "../Search.type";

import s from "../Search.module.less";

interface ISentimentChartProps extends HTMLAttributes<HTMLDivElement> {
  isLoading: boolean;
  total: IPieChartUnit;
  data: IPieChartUnit[];
  filterToSpecificItem: FilterFunctionType;
}

const SentimentChart: FC<ISentimentChartProps> = ({
  data,
  total,
  isLoading,
  filterToSpecificItem,
  className,
}) => {
  const { t } = useTranslation();

  const chartData = data.filter(unit => unit.value !== 0);

  const onChartClick = (event: { payload: { rawName: string } }) => {
    filterToSpecificItem({
      sentiments: [event.payload.rawName],
    });
  };

  return (
    <Card
      className={classNames(s.bbSentimentContainer, className)}
      data-stonly-target="intelligence-search__sentiment"
      headerTitle={t("components:searchPanel:sentiment:title")}
    >
      <div className={s.bbSearchPanelRootCard}>
        {isLoading ? (
          <div className={s.bbSearchPanelCardLoading}>
            <Loading isLoading />
          </div>
        ) : chartData.length ? (
          <PieChart
            data={data}
            isShowPercent
            width={CHART_WIDTH}
            height={CHART_HEIGHT}
            defaultValue={total}
            percentagePrecision={1}
            enableCursor="pointer"
            pieProps={{
              cx: 142,
              cy: 120,
              innerRadius: 80,
              outerRadius: 95,
              stroke: "none",
              nameKey: "name",
              dataKey: "value",
              labelLine: false,
              onClick: onChartClick,
              animationDuration: 500,
              paddingAngle: chartData.length === 1 ? 0 : 5,
            }}
          />
        ) : (
          <Empty
            className={s.bbSearchPanelCardEmpty}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t("components:comments:tableEmpty")}
            imageStyle={{
              height: 40,
            }}
          />
        )}
      </div>
    </Card>
  );
};

export default SentimentChart;
