import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import React, { useState, useEffect, useMemo } from "react";
import classNames from "classnames";

import s from "./Aggregations.module.less";

import { Col, Empty } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import AggregationStat from "./aggregationStat/AggregationStat";
import { Card, Loading, moveArrayItemToNewIndex } from "@bbdevcrew/bb_ui_kit_fe";

import { IAggregationsProps } from "./Aggregations.type";
import { aggregationInfoKey } from "@utils/aggregationName";
import { filterDataSelector } from "@store/filters/selectors";

const Aggregations: React.FC<IAggregationsProps> = ({
  data: aggregations,
  isLoading,
  colors,
  widthInfo,
  className,
}) => {
  const { t } = useTranslation();
  const [hasAggregations, setHasAggregations] = useState<boolean>(false);
  const filters = useSelector(filterDataSelector);
  const dateRange = useMemo(() => filters?.data_range_option || "last_30_days", [filters]);
  const aggregationsInfoKey = useMemo(() => aggregationInfoKey(dateRange), [dateRange]);

  useEffect(() => {
    if (filters) {
      if (filters.data_range_option && filters.data_range_option !== "custom") {
        setHasAggregations(true);
      } else {
        setHasAggregations(false);
      }
    }
  }, [filters]);

  return (
    <Card
      className={classNames(s.bbAggregationsCard, className)}
      data-stonly-target="intelligence-search__overview"
      headerTitle={t("components:searchPanel:cardStat:title")}
      headerAction={
        <span className={s.bbAggregationsRightTitle}>
          {hasAggregations
            ? t("components:searchPanel:cardStat:rightTitle_" + aggregationsInfoKey)
            : ""}{" "}
          {hasAggregations ? t("components:searchPanel:cardStat:rightTitleEnd") : ""}
        </span>
      }
    >
      {isLoading ? (
        <div className={s.bbAggregationsLoading}>
          <Loading
            isLoading
            size="large"
            tip={t("pages:loading:spinText")}
            indicator={<LoadingOutlined color="primary" style={{ fontSize: 24 }} />}
          />
        </div>
      ) : aggregations && aggregations.items ? (
        moveArrayItemToNewIndex(aggregations.items, 2, 1).map((item, key) => {
          return (
            <Col key={key} xs={24}>
              <div className={s.bbAggregationsItem}>
                <AggregationStat
                  item={item}
                  widthInfo={widthInfo || 154}
                  color={colors[item.id as keyof typeof colors]}
                />
              </div>
            </Col>
          );
        })
      ) : (
        <Empty
          className={s.bbAggregationsCardEmpty}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          imageStyle={{
            height: 40,
          }}
        />
      )}
    </Card>
  );
};

export default Aggregations;
