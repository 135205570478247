import "d3-transition";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import React, { FC, useState, useEffect, useMemo } from "react";

import { Empty, Row, Col } from "antd";
import ReactResizeDetector from "react-resize-detector";
import YTNotAvailableChip from "../../_common/ytNotAvailableChip";
import { Card, WordCloud, Loading, TabPane, TabsScrollable } from "@bbdevcrew/bb_ui_kit_fe";

import "./Tooltip.module.less";
import s from "./MostUsedEmojis.module.less";

import {
  getCloudSize,
  getCloudPadding,
  getHoverCallback,
  getCloudMaxWords,
  calculateWordColor,
} from "./helpers";
import { Word } from "react-wordcloud";
import { IFilters } from "@store/filters/types";
import { useYTSelected } from "@utils/useYTselected";
import { IWordCloudItems, IWordCloudProps } from "@store/overview/types";

interface IMostUsedEmojisProps {
  loading: boolean;
  data: IWordCloudItems[];
  filters: IFilters;
  filterToSpecificItem?: (item: IFilters) => void;
}

const MostUsedEmojis: FC<IMostUsedEmojisProps> = ({
  filters,
  data,
  loading,
  filterToSpecificItem,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isYTFilterSelected = useYTSelected(filters?.platform_types || []);

  const [cloud, setCloud] = useState<IWordCloudItems[]>([]);
  const [cloudType, setCloudType] = useState("all");

  useEffect(() => {
    setCloud(data);
  }, [data]);

  const onWordClick = (word: Word) => {
    if (word && word.keywords) {
      const wordFilters =
        cloudType === "all"
          ? {
              keyword_query: word.keywords,
            }
          : {
              keyword_query: word.keywords,
              sentiments: [cloudType],
            };
      filterToSpecificItem?.({
        ...filters,
        ...wordFilters,
      });

      navigate(
        {
          search: "?section=listen-sentiment_details",
        },
        {
          state: {
            filters: {
              ...filters,
              ...wordFilters,
            },
          },
        },
      );
    }
  };

  const callbacks = {
    onWordMouseOut: getHoverCallback("onWordMouseOut", onWordClick),
    onWordMouseOver: getHoverCallback("onWordMouseOver", onWordClick),
    getWordColor: (word: Word) => calculateWordColor(word.value, cloudType),
    getWordTooltip: () => t("components:mostUsedEmojis:tooltips:explore"),
  };

  const keywords = useMemo(() => {
    const selectedItems = data.find(item => item.id === cloudType);

    return selectedItems?.keywords || [];
  }, [data, cloudType]);

  const generateTab = () => {
    return (
      <ReactResizeDetector handleWidth>
        {(props: { width: number }) => {
          return loading ? (
            <div className={s.bbRootWordCloudTrendsLoading}>
              <Loading isLoading />
            </div>
          ) : cloud !== null && props.width && keywords.length ? (
            <WordCloud
              words={keywords}
              callbacks={callbacks}
              onClick={onWordClick}
              size={getCloudSize(props.width)}
              maxWords={getCloudMaxWords(props.width)}
              options={{
                padding: getCloudPadding(props.width),
                rotationAngles: [0, 0],
                fontFamily: "Manrope",
                deterministic: true,
                fontSizes: [30, 50],
                transitionDuration: 0,
                enableOptimizations: true,
              }}
            />
          ) : (
            <Empty
              className={s.bbWorldCloudEmpty}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t("components:empty:description")}
              imageStyle={{
                height: 40,
              }}
            />
          );
        }}
      </ReactResizeDetector>
    );
  };

  const onChangeTab = (tabKey: string) => {
    setCloudType(tabKey as keyof IWordCloudProps);
  };

  return (
    <div
      data-cy="most-used-emojis-container"
      className={s.bbAnalysisTrendsCardWrapper}
      data-stonly-target="intelligence-overview__most-used-emojis"
    >
      <Card
        headerTitle={t("components:mostUsedEmojis:title")}
        headerSubTitle={t("components:mostUsedEmojis:subTitle")}
        headerAction={isYTFilterSelected ? <YTNotAvailableChip /> : null}
      >
        <div className={s.bbRootAnalysisTrends}>
          <Row>
            <Col xs={24}>
              <Row justify="space-around" className={s.bbAnalysisTrendsContent}>
                <Col flex={1}>
                  <div className={s.bbRootWordCloudTrends} data-cy="wordcloud-container">
                    <TabsScrollable
                      centered
                      noUnderline
                      activeKey={cloudType}
                      defaultActiveKey="all"
                      onChange={onChangeTab}
                      className={s.bbTabs}
                    >
                      <TabPane tab={t(`components:analysisTrends:tabs:all`)} tabKey="all">
                        {generateTab()}
                      </TabPane>
                      <TabPane tab={t(`components:analysisTrends:tabs:positive`)} tabKey="positive">
                        {generateTab()}
                      </TabPane>
                      <TabPane tab={t(`components:analysisTrends:tabs:negative`)} tabKey="negative">
                        {generateTab()}
                      </TabPane>
                    </TabsScrollable>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
};

export default MostUsedEmojis;
