import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Empty } from "antd";
import { PieChart, IPieChartSector } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./UserLanguagesChart.module.less";

import { IPieChartUnit } from "../../../search/Search.type";
import { IUserLanguagesChartItem } from "@store/overview/types";
import { IUserLanguageSector, IUserLanguagesChartProps } from "./UserLanguagesChart.type";

const UserLanguagesChart: React.FC<IUserLanguagesChartProps> = ({
  data,
  chartWidth,
  colorData,
  filterData,
  filterToSpecificItem,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const generateChartData = (items: IUserLanguagesChartItem[]) => {
    let chartData: IPieChartUnit[] = [];

    chartData = items.map((item, index) => {
      return {
        name: item.label || "",
        rawName: item.id || "",
        value: item.count || 0,
        color: colorData[index % colorData.length],
        tooltipString: item.label || "",
      };
    });

    return chartData;
  };

  const onSectorClick = (activeSectorData: IPieChartSector) => {
    const sectorData: IUserLanguageSector = activeSectorData;

    const filters = {
      ...filterData,
      languages: [sectorData.rawName] as string[],
    };
    filterToSpecificItem?.(filters);
    navigate(
      {
        search: "?section=listen-sentiment_details",
      },
      {
        state: { filters },
      },
    );
  };

  return (
    <>
      {data?.length ? (
        <>
          <div className={s.bbUserLanguagesChart} data-cy="overview-user-languages-chart">
            <PieChart
              key={Math.random()}
              data={generateChartData(data)}
              width={chartWidth / 2 - 50}
              height={350}
              isShowPercent
              percentagePrecision={1}
              enableCursor="pointer"
              hoverStrokeWidth={90}
              hoverStrokeInnerRadius={90}
              hoverStrokeOuterRadius={90}
              onSectorClick={onSectorClick}
              defaultValue={{
                name: data[0] ? data[0].label : "",
                value: data[0] ? data[0].count : 0,
                color: colorData[0],
              }}
              pieProps={{
                stroke: "none",
                nameKey: "name",
                dataKey: "value",
                cx: 82,
                cy: 140,
                labelLine: false,
                paddingAngle: 0,
                key: Math.random(),
                innerRadius: chartWidth < 350 ? 40 : 50,
                outerRadius: chartWidth < 350 ? 110 : 140,
                animationDuration: 500,
              }}
            />
          </div>
          <div className={s.bbUserLanguagesChartLegend} data-cy="user-languages-chart-legend">
            {data.map((item, index) => (
              <div key={item.id}>
                <div
                  className={s.bbLegendItemBullet}
                  data-cy="user-languages-chart-legend-dot"
                  style={{
                    backgroundColor: colorData[index % colorData.length],
                  }}
                ></div>
                <span className={s.bbLegendItemLabel} data-cy="user-languages-chart-legend-label">
                  {item.id}
                </span>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className={s.bbUserLanguagesEmptyContainer}>
          <Empty
            className={s.bbUserLanguagesEmpty}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t("components:empty:description")}
          />
        </div>
      )}
    </>
  );
};

export default UserLanguagesChart;
