import { Reducer, compose, Middleware, createStore, applyMiddleware, combineReducers } from "redux";
import { Action, StateType } from "typesafe-actions";
import { createEpicMiddleware, Epic } from "redux-observable";
import { flattenEpics, generateEpics } from "./rootEpic";

/* ------------------ EPICS ------------------ */
import * as authEpics from "./auth/epics";
import * as savedFiltersEpics from "./savedFilters/epics";
import * as repliesEpics from "./replies/epics";
import * as repliesAIPromptsEpics from "./repliesAIPrompts/epics";
import * as commentsEpics from "./comments/epics";
import * as commentTagsEpics from "./commentTags/epics";
import * as dashboardEpics from "./dashboard/epics";
import * as overviewEpics from "./overview/epics";
import * as searchEpics from "./search/epics";
import * as kpiDataEpics from "./kpiData/epics";
import * as linkedInReactionsEpic from "./linkedInReactions/epics";
import * as compareEpics from "./compare/epics";
import * as compareProjectsEpics from "./compareProjects/epics";
import * as notificationsEpics from "./notifications/epics";
import * as postsEpics from "./posts/epics";
import * as assignEpics from "./assign/epics";
import * as workflowsEpics from "./workflows/epics";
import * as hidingConfigEpics from "./hidingConfig/epics";
import * as publishingsEpics from "./publishings/epics";
import * as moderationStreamsEpics from "./streams/epics";
import * as inboxStatsEpics from "./inboxStats/epics";
import * as customTagsEpics from "./customTags/epics";
import * as aggregationsEpics from "./aggregations/epics";
import * as assetsEpics from "./assets/epics";
import * as accountsEpics from "./accounts/epics";
import * as assetGroupsEpics from "./assetGroup/epics";
import * as documentEpics from "./documents/epics";
import * as userEpics from "./user/epics";
import * as hubspotEpics from "./hubspot/epics";
import * as apiLoggerEpic from "./apiLogger/epics";
import * as onboardingPlatformsEpics from "./onboardingPlatforms/epics";
import * as onboardingPlatformTypesEpics from "./onboardingPlatformTypes/epics";
import * as meEpics from "./me/epics";
import * as usersEpic from "./users/epics";
import * as tiktokHashtagsEpics from "./tiktokHashtags/epics";
import * as tiktokAppEpics from "./tiktokApp/epics";
import * as checkoutEpic from "./checkout/epics";
import * as postTagsEpic from "./postTags/epics";
import * as languagesEpic from "./languages/epics";
import * as autocompleteEpics from "./autocomplete/epics";
import * as captionsAIPromptsEpics from "./captionsAIPrompts/epics";
import * as shortenedSearchesEpics from "./shortenedSearches/epics";
import * as listenEpics from "./listen/epics";
import * as sourcesEpics from "./sources/epics";
import * as integrationsEpics from "./integrations/epics";
import * as modulesEpics from "./modules/epics";
import * as reportsEpics from "./reports/epics";
import * as featureVisibilityFlagsEpics from "./companySettings/epics";
import * as aiInsightsEpics from "./aiInsights/epics";

/* ------------------ REDUCERS ------------------ */
import { authReducer } from "./auth";
import { attachmentReducer } from "./attachment";
import { savedFiltersReducer } from "./savedFilters";
import { repliesReducer } from "./replies";
import { commentsReducer } from "./comments";
import { commentTagsReducer } from "./commentTags";
import { repliesAIPromptsReducer } from "./repliesAIPrompts";
import { dashboardReducer } from "./dashboard";
import { overviewReducer } from "./overview";
import { searchReducer } from "./search";
import { kpiDataReducer } from "./kpiData";
import { compareReducer } from "./compare";
import { compareProjectsReducer } from "./compareProjects";
import { notificationsReducer } from "./notifications";
import { postsReducer } from "./posts";
import { assignReducer } from "./assign";
import { workflowsReducer } from "./workflows";
import { alertTypesReducer } from "./alertTypes";
import { hidingConfigReducer } from "./hidingConfig";
import { publishingsReducer } from "./publishings";
import { streamsReducer } from "./streams";
import { inboxStatsReducer } from "./inboxStats";
import { customTagsReducer } from "./customTags";
import { aggregationsReducer } from "./aggregations";
import { accountsReducer } from "./accounts";
import { assetsReducer } from "./assets";
import { assetGroupsReducer } from "./assetGroup";
import { documentReducer } from "./documents";
import { userReducer } from "./user";
import { hubspotReducer } from "./hubspot";
import { meReducer } from "./me";
import { usersReducer } from "./users";
import { onboardingPlatformsReducer } from "./onboardingPlatforms";
import { onboardingPlatformTypesReducer } from "./onboardingPlatformTypes";
import { tiktokHashtagsReducer } from "./tiktokHashtags";
import { tiktokAppReducer } from "./tiktokApp";
import { filtersReducer } from "./filters";
import { checkoutReducer } from "./checkout";
import { postTagsReducer } from "./postTags";
import { languagesReducer } from "./languages";
import { captionsAIPromptsReducer } from "./captionsAIPrompts";
import { shortenedSearchesReducer } from "./shortenedSearches";
import { autocompleteOptionsReducer } from "./autocomplete";
import { listenReducer } from "./listen";
import { sourcesReducer } from "./sources";
import { integrationsReducer } from "./integrations";
import { modulesReducer } from "./modules";
import { reportsReducer } from "./reports";
import { aiInsightsReducer } from "./aiInsights";
import { featureVisibilityFlagsReducer } from "./companySettings";

export const reducers = {
  attachment: attachmentReducer,
  comments: commentsReducer,
  savedFilters: savedFiltersReducer,
  commentTags: commentTagsReducer,
  replies: repliesReducer,
  repliesAIPrompts: repliesAIPromptsReducer,
  streams: streamsReducer,
  dashboard: dashboardReducer,
  overview: overviewReducer,
  search: searchReducer,
  kpiData: kpiDataReducer,
  compare: compareReducer,
  compareProjects: compareProjectsReducer,
  posts: postsReducer,
  notifications: notificationsReducer,
  publishings: publishingsReducer,
  assign: assignReducer,
  workflows: workflowsReducer,
  hidingConfig: hidingConfigReducer,
  alertTypes: alertTypesReducer,
  inboxStats: inboxStatsReducer,
  customTags: customTagsReducer,
  aggregations: aggregationsReducer,
  accounts: accountsReducer,
  assets: assetsReducer,
  assetGroups: assetGroupsReducer,
  documents: documentReducer,
  user: userReducer,
  hubspot: hubspotReducer,
  auth: authReducer,
  me: meReducer,
  users: usersReducer,
  onboardingPlatforms: onboardingPlatformsReducer,
  onboardingPlatformTypes: onboardingPlatformTypesReducer,
  tiktokApp: tiktokAppReducer,
  tiktokHashtags: tiktokHashtagsReducer,
  filters: filtersReducer,
  checkout: checkoutReducer,
  postTags: postTagsReducer,
  languages: languagesReducer,
  captionsAIPrompts: captionsAIPromptsReducer,
  shortenedSearches: shortenedSearchesReducer,
  autocompleteOptions: autocompleteOptionsReducer,
  listen: listenReducer,
  sources: sourcesReducer,
  integrations: integrationsReducer,
  modules: modulesReducer,
  reports: reportsReducer,
  featureVisibilityFlags: featureVisibilityFlagsReducer,
  aiInsights: aiInsightsReducer,
};

const epicMiddleware = createEpicMiddleware();
const flattenedEpics = flattenEpics(
  [
    accountsEpics,
    aggregationsEpics,
    aiInsightsEpics,
    apiLoggerEpic,
    assetGroupsEpics,
    assetsEpics,
    assignEpics,
    authEpics,
    autocompleteEpics,
    captionsAIPromptsEpics,
    checkoutEpic,
    commentsEpics,
    commentTagsEpics,
    compareEpics,
    compareProjectsEpics,
    customTagsEpics,
    dashboardEpics,
    documentEpics,
    featureVisibilityFlagsEpics,
    hidingConfigEpics,
    hubspotEpics,
    inboxStatsEpics,
    kpiDataEpics,
    languagesEpic,
    linkedInReactionsEpic,
    listenEpics,
    meEpics,
    moderationStreamsEpics,
    modulesEpics,
    notificationsEpics,
    onboardingPlatformsEpics,
    onboardingPlatformTypesEpics,
    overviewEpics,
    postTagsEpic,
    postsEpics,
    publishingsEpics,
    repliesAIPromptsEpics,
    repliesEpics,
    reportsEpics,
    savedFiltersEpics,
    searchEpics,
    shortenedSearchesEpics,
    sourcesEpics,
    integrationsEpics,
    tiktokHashtagsEpics,
    tiktokAppEpics,
    userEpics,
    usersEpic,
    workflowsEpics,
  ].map(Object.values),
);

const isInBrowser = typeof document !== "undefined";

const composeEnhancers =
  // eslint-disable-next-line
  (isInBrowser && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const setupStore = (reducer: Reducer, ...middlewares: Middleware[]) =>
  createStore(reducer, composeEnhancers(applyMiddleware(...middlewares)));

export const store = setupStore(combineReducers(reducers), epicMiddleware);

epicMiddleware.run(
  // eslint-disable-next-line
  generateEpics(flattenedEpics) as Epic<Action<any>>,
);

export type RootState = StateType<ReturnType<typeof combineReducers>> & StateType<typeof reducers>;
