import React from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

import { FilterTagValueType } from "../../tagList/TagListConfig";

import s from "./Keywords.module.less";

export function KeywordsTooltipContent(value: FilterTagValueType) {
  const { t } = useTranslation();

  if (typeof value === "object") {
    if (!value?.length) {
      return undefined;
    }

    return value?.map((val, idx) => {
      const text = i18next.exists(`tags:values:${val}`)
        ? t(`tags:values:${val}`)
        : Array.isArray(val)
          ? val.join(" ")
          : val;

      return (
        <span key={idx} className={s.bbDisplayBlock}>
          {text}
        </span>
      );
    });
  }

  return "";
}
