import React from "react";
import classNames from "classnames";

import Breadcrumbs from "../../_common/breadcrumbs";

import s from "./Header.module.less";

import { IHeaderProps } from "./Header.types";

export const Header: React.FC<IHeaderProps> = ({ children, className }) => {
  return (
    <div className={classNames(s.bbHeader, { className })}>
      <Breadcrumbs />
      <div className={s.bbHeaderChildrenWrapper}>{children}</div>
    </div>
  );
};
