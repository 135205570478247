import React from "react";
import { useTranslation } from "react-i18next";

import s from "./Keywords.module.less";

import { DEFAULT_KEYWORD_AMOUNT_LIMIT } from "./KeywordsDropdown";

export interface IExplanationTooltipProps {
  limited?: boolean;
}

const ExplanationTooltip = ({ limited }: IExplanationTooltipProps) => {
  const { t } = useTranslation();

  const part1 = (
    <>
      {t("components:filters:keywords:explanationTooltip:line1")}:
      <ul className={s.bbExplanationTooltipList}>
        <li>{t("components:filters:keywords:explanationTooltip:and")}</li>
        <li>{t("components:filters:keywords:explanationTooltip:or")}</li>
        <li>{t("components:filters:keywords:explanationTooltip:not")}</li>
        <li>{t("components:filters:keywords:explanationTooltip:operator")}</li>
      </ul>
    </>
  );

  const part2Default = (
    <>
      <ul className={s.bbExplanationTooltipList}>
        <li>{t("components:filters:keywords:explanationTooltip:phrases")}</li>
        <li>{t("components:filters:keywords:explanationTooltip:exactMatch")}</li>
        <li>
          {t("components:filters:keywords:explanationTooltip:maxKeywords", {
            count: DEFAULT_KEYWORD_AMOUNT_LIMIT,
          })}
        </li>
      </ul>
    </>
  );

  const part2Unlimited = (
    <>
      {t("components:filters:keywords:explanationTooltipUnlimited:line2")}:
      <ul className={s.bbExplanationTooltipList}>
        <li>{t("components:filters:keywords:explanationTooltipUnlimited:modifiers")}</li>
      </ul>
    </>
  );

  const part3 = (
    <>
      {t("components:filters:keywords:explanationTooltip:line3")}:
      <ul className={s.bbExplanationTooltipList}>
        <li>{t("components:filters:keywords:explanationTooltip:example1")}</li>
        <li>{t("components:filters:keywords:explanationTooltip:example2")}</li>
        <li>{t("components:filters:keywords:explanationTooltip:example3")}</li>
      </ul>
    </>
  );

  return (
    <div className={s.bbExplanationTooltip}>
      {part1}
      {limited ? part2Default : part2Unlimited}
      {part3}
    </div>
  );
};

export default ExplanationTooltip;
