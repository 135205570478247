import classNames from "classnames";
import React, { FC, useMemo, useState, useEffect } from "react";

import { Icon, Input, Dropdown, Tooltip, Chip } from "@bbdevcrew/bb_ui_kit_fe";
// eslint-disable-next-line max-len
import KeywordsDropdownBasic from "@components/_common/AppFilter/filters/Keywords/KeywordsDropdownBasic";

import s from "@components/_common/AppFilter/filters/Keywords/Keywords.module.less";
import modalStyles from "./ZendeskTicketForm.module.less";

import {
  isChipValid,
  isChipPhrase,
  isChipPhraseEmpty,
} from "@components/_common/AppFilter/inputChip/InputChipConfig";
import {
  pickKeywords,
  flattenKeywords,
  unflattenKeywords,
  KeywordQueryType,
} from "@components/_common/AppFilter/inputChip/InputChipQueryConfig";
import { generateKeywordsInputValueState } from "@components/_common/SelectTrigger/helpers";

import { ChevronDownIcon, ChevronUpIcon, CloseIcon } from "@bbdevcrew/bb_ui_kit_fe";
import { FormInstance } from "antd";

interface TagsInputProps {
  customprops: {
    name: string | string[];
    form: FormInstance;
    onFocus?: (fieldName: string) => void;
  };
  placeholder?: string;
  dropdownMatchSelectWidth?: boolean;
  onChange?: (value: KeywordQueryType | string) => void;
  wrapperClassName?: string;
}

export const TagsInput: FC<TagsInputProps> = ({
  placeholder,
  customprops: { form, name },
  dropdownMatchSelectWidth,
  wrapperClassName,
  onChange,
}) => {
  const fieldValue = form.getFieldValue(name);
  const { selected } = useMemo(
    () => generateKeywordsInputValueState(pickKeywords(flattenKeywords(fieldValue))),
    [fieldValue],
  );
  const [inputChipValue, setInputChipValue] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    if (!isDropdownOpen) {
      const value: string[] = fieldValue || [];

      if (inputChipValue && !value.includes(inputChipValue.trim())) {
        if (
          isChipValid(inputChipValue) &&
          !isChipPhraseEmpty(inputChipValue) &&
          !isChipPhrase(inputChipValue)
        ) {
          onChange?.(inputChipValue);
        }
      }

      onChange?.(unflattenKeywords(value.map(keyword => keyword.trim())));
      setInputChipValue("");
    }
    // eslint-disable-next-line
  }, [isDropdownOpen]);

  const setDropdownOpenState = (open: boolean) => setIsDropdownOpen(open);
  const onDropdownClick = () => setIsDropdownOpen(!isDropdownOpen);
  const onClear = () => onChange?.([]);

  const tooltipElement = (
    <>
      {fieldValue?.map((keyword: string, index: number) => (
        <span key={`${keyword}-${index}`} className={s.bbDisplayBlock}>
          {keyword}
        </span>
      ))}
    </>
  );

  return (
    <Dropdown
      open={isDropdownOpen}
      placement="bottomRight"
      setOpen={setDropdownOpenState}
      menuClassName={!dropdownMatchSelectWidth ? s.bbKeywordsMenu : ""}
      matchTriggerWidth={dropdownMatchSelectWidth}
      trigger={
        <div
          id="tags-trigger"
          data-cy="tags-trigger"
          onClick={onDropdownClick}
          className={s.bbKeywordsTrigger}
        >
          <Input
            _size="md"
            id={"tags-trigger"}
            value={placeholder}
            data-testid="tags-input"
            placeholder={placeholder}
            wrapperClassName={classNames(s.bbKeywordsTriggerWrapper, wrapperClassName, {
              [s.bbKeywordsTriggerWrapperFocused]: isDropdownOpen,
            })}
            suffixEl={
              <>
                <Icon
                  _size="sm"
                  icon={CloseIcon}
                  onClick={onClear}
                  className={s.bbKeywordsTriggerIconClose}
                />
                <Icon
                  _size="sm"
                  className={s.bbKeywordsTriggerIconChevron}
                  icon={isDropdownOpen ? ChevronUpIcon : ChevronDownIcon}
                />
              </>
            }
          />

          {!!selected && (
            <div
              className={modalStyles.bbZendeskTicketFormTagsIndicator}
              data-testid="selected-excluded-indicator"
            >
              {!!selected && (
                <Tooltip placement="topLeft" title={tooltipElement}>
                  <Chip
                    _size="xs"
                    _type="primary"
                    text={selected}
                    className={modalStyles.bbZendeskTicketFormTagsIndicatorSelected}
                  />
                </Tooltip>
              )}
            </div>
          )}
        </div>
      }
    >
      <KeywordsDropdownBasic name={name} form={form} />
    </Dropdown>
  );
};
