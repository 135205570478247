import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import PresetSelector from "@components/_common/PresetSelector";
import StreamsModal from "../StreamsModal";

import { getCurrentCareCpsStreamSelector } from "@store/streams/selectors";
import { useAppSidebar } from "@components/_common/AppSidebar/helpers";

import { ModalViewType } from "../StreamsModal/StreamsModal.types";

export const StreamSelector: React.FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation();
  const { isCollapsed } = useAppSidebar();
  const [modalView, setModalView] = useState<ModalViewType>(false);
  const stream = useSelector(getCurrentCareCpsStreamSelector);

  return (
    <>
      <PresetSelector
        isCollapsed={isCollapsed}
        value={stream}
        onClick={() => setModalView("list")}
        active={!!modalView}
        allLabel={t("components:careCps:streamsModal:allStreams")}
        className={className}
      />
      <StreamsModal view={modalView} setView={setModalView} close={() => setModalView(false)} />
    </>
  );
};
