import { useDispatch, useSelector } from "react-redux";
import React, { FC, useEffect } from "react";
import { Navigate, Route, Routes, useNavigate, useSearchParams } from "react-router-dom";
import { useRoutes } from "@utils/useRoutes";

import PrivateRoute from "./PrivateRoute";
import NotFoundView from "@containers/NotFoundView/NotFoundView";
import AppLayout from "@components/_common/AppLayout/AppLayout";
import CareCPSInbox from "@components/care-cps/CareCPSInbox";

import { filterDataSelector } from "@store/filters/selectors";
import {
  getCareCpsStreamsSelector,
  getCareCpsStreamsSelectorFailure,
  getCareCpsStreamsSelectorPending,
  getCareCpsStreamsSelectorSuccessful,
  getCurrentCareCpsStreamSelector,
} from "@store/streams/selectors";
import { postModuleSectionsListAction } from "@store/modules/actions";
import {
  getCareCpsStreamsAction,
  resetCareCpsStreamsAction,
  setCareCpsStreamAction,
} from "@store/streams/actions";

import { DEFAULT_CONVERSATION_LIST } from "@components/care-cps/CareCPSInbox/CareCPSInbox.helpers";

import { IMenuItem } from "@components/_common/AppHeader/AppHeader.type";
import { IRestrictedRoutesProps } from "./Routes.type";

const CareCPSRoutes: FC<IRestrictedRoutesProps> = ({ menu, path }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { redirectCallback } = useRoutes(path);
  const dispatch = useDispatch();

  const streams = useSelector(getCareCpsStreamsSelector);
  const fetched = useSelector(getCareCpsStreamsSelectorSuccessful);
  const fetching = useSelector(getCareCpsStreamsSelectorPending);
  const fetchFailed = useSelector(getCareCpsStreamsSelectorFailure);
  const filters = useSelector(filterDataSelector);
  const stream = useSelector(getCurrentCareCpsStreamSelector);

  const shouldFetch = !fetched && !fetching && !fetchFailed;

  useEffect(() => {
    if (shouldFetch) {
      dispatch(getCareCpsStreamsAction());
    }
  }, [shouldFetch, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetCareCpsStreamsAction());
    };
  }, [dispatch]);

  useEffect(() => {
    if (fetched && !fetchFailed && streams.length > 0) {
      const streamId = searchParams.get("stream");
      const streamIndex = streams.findIndex(({ id }) => id === streamId);
      const currentStreamIndex = streamIndex !== -1 ? streamIndex : 0;
      dispatch(setCareCpsStreamAction(streams[currentStreamIndex]));
    }
  }, [fetched, fetchFailed, streams, dispatch, searchParams]);

  useEffect(() => {
    if (stream) {
      dispatch(
        postModuleSectionsListAction({
          module: "care_cps",
          filters,
          streamId: stream.id,
        }),
      );
      const params = new URLSearchParams(searchParams);
      params.set("stream", stream.id);
      navigate({
        search: params.toString(),
      });
    }
  }, [stream, filters, dispatch, searchParams, navigate]);

  useEffect(() => {
    searchParams.set("section", searchParams.get("section") || DEFAULT_CONVERSATION_LIST);
  }, [searchParams]);

  return (
    <AppLayout
      basename="care-cps"
      menu={menu as unknown as IMenuItem[]}
      selectedKey={(path && path.replace(/\//g, "")) || "streams"}
    >
      {{
        content: (
          <Routes>
            <>
              <Route
                path="/"
                element={
                  <Navigate
                    replace
                    to={{
                      pathname: "streams",
                      search: new URLSearchParams(searchParams).toString(),
                    }}
                  />
                }
              />
              <Route
                path="streams"
                element={<PrivateRoute>{stream && <CareCPSInbox stream={stream} />}</PrivateRoute>}
              />
              <Route
                path="unauthorized"
                element={<NotFoundView redirectCallback={redirectCallback} type="403" />}
              />
              <Route path="*" element={<NotFoundView redirectCallback={redirectCallback} />} />
            </>
          </Routes>
        ),
      }}
    </AppLayout>
  );
};

export default CareCPSRoutes;
