import { useDispatch, useSelector } from "react-redux";
import React, { FC, UIEvent } from "react";

import List from "rc-virtual-list";
import { ListRef } from "rc-virtual-list/lib/List";

import Reply from "../Reply/Reply";
import { BrandReply } from "./BrandReply";
import MessageCard from "../MessageCard/MessageCard";
import MessageCardWrapper from "./MessageCardWrapper";
import DeleteReplyAlert from "../MessageCard/replyDeleteAlert/ReplyDeleteAlert";
import MessageActionsFooter from "../MessageCard/MessageActionsFooter";
import { DeleteCommentConfirmationModal } from "../DeleteCommentConfirmationModal";

import { deletingBrandReplySelector, deletedReplyIdSelector } from "@store/replies/selectors";
import { deleteBrandReplyAction } from "@store/replies/actions";

import { useBrandReplyDropdownItems } from "../MessageCard/dropdowns/useBrandReplyDropdownItems";
import { useCommentActionDropdownItems } from "../MessageCard/dropdowns/useCommentActionDropdownItems"; // eslint-disable-line max-len

import s from "./MessageList.module.less";

import { IMessageListChildProps, IMessageListChildrenProps } from "./MessageList.type";
import { getMessage } from "./MessageList.helpers";
import { PostPlacementEnum } from "../Social/Post/Post.type";

export function MessageListChild({
  item,
  filters,
  comment,
  replyData,
  isReplyBtnLoading,
  showReplyBox,
  onInternalReply,
  isReplyingComment,
  onClose,
  listId,
}: IMessageListChildProps) {
  const dispatch = useDispatch();

  const isDeletingBrandReply = useSelector(deletingBrandReplySelector);
  const deletedReplyId = useSelector(deletedReplyIdSelector);

  const {
    id,
    owned,
    message,
    username,
    sentiment,
    is_hidden,
    newlyAdded,
    brand_reply,
    is_ignored,
    action_error,
    assigned_users,
    comment_phrase,
    created_time_pretty,
    is_ai_highlighted,
    message_translated,
    language_detected,
    private_replied_public_comment_id,
  } = item;

  const onDeleteReply = (replyId: string, isIgnored: boolean, isAiHighlighted: boolean) => {
    dispatch(deleteBrandReplyAction(replyId, isIgnored, isAiHighlighted));
  };

  const { brandReplyDropdownItems, showDeleteReplyAlert, toggleDeleteReplyAlert } =
    useBrandReplyDropdownItems();

  const { actionDropdownItems, deleteConfirmationModal } = useCommentActionDropdownItems(
    item,
    filters,
    replyData.reply_user.platform.id,
  );

  const actions = owned
    ? brandReplyDropdownItems
    : !isReplyingComment(id)
      ? actionDropdownItems
      : [];

  const hideActions =
    !!owned &&
    (!!private_replied_public_comment_id || replyData.post_placement === PostPlacementEnum.REVIEW);
  return (
    <div className={s.bbMessageListItem}>
      <MessageCardWrapper isChildMessage={!!replyData.parent}>
        <MessageCard
          author={username}
          message={getMessage(message, comment)}
          messageTranslated={language_detected !== "en" ? message_translated : ""}
          isHidden={is_hidden}
          key={`message-${id}`}
          date={created_time_pretty}
          actionError={action_error}
          hideActions={hideActions}
          commentPhrase={comment_phrase}
          actionDropdownItems={actions}
          sentiment={!owned ? sentiment : undefined}
          size={isReplyingComment(id) ? "lg" : "md"}
          recommendationType={comment?.recommendation_type}
          assigningCommentId={!isReplyingComment(id) && !owned ? id : undefined}
          assignedUsers={isReplyingComment(id) ? assigned_users || [] : []}
          type={owned || newlyAdded || isReplyingComment(id) ? "highlighted" : "default"}
          commentTooltips={comment?.tooltips}
          footer={
            <>
              {isReplyingComment(id) && (
                <MessageActionsFooter
                  comment={comment}
                  filters={filters}
                  replyData={replyData}
                  assigned_users={assigned_users}
                  onClose={onClose}
                  listId={listId}
                />
              )}
              {owned && showDeleteReplyAlert && (
                <DeleteReplyAlert
                  onApprove={() => onDeleteReply(id, is_ignored, is_ai_highlighted)}
                  onCancel={toggleDeleteReplyAlert}
                  isDeleting={isDeletingBrandReply && deletedReplyId === id}
                />
              )}
            </>
          }
        />
      </MessageCardWrapper>

      {showReplyBox(id) && (
        <Reply
          comment={comment}
          onClickReply={onInternalReply}
          replyUser={replyData.reply_user}
          replierInfo={replyData.replier_info}
          isReplyBtnLoading={isReplyBtnLoading}
          maxMessageLength={replyData.max_message_length}
          replyingCommentId={replyData.replying_comment_id}
          suggestedReplyMessage={replyData?.suggested_reply_message}
          disabledMentions={brand_reply?.warning_type === "ig_mention_removed"}
        />
      )}

      {!!brand_reply && (
        <BrandReply
          brand_reply={brand_reply}
          actionDropdownItems={brandReplyDropdownItems}
          showDeleteReplyAlert={showDeleteReplyAlert}
          onCancelDeleteReply={toggleDeleteReplyAlert}
          onDeleteReply={() => onDeleteReply(id, is_ignored, is_ai_highlighted)}
        />
      )}

      <DeleteCommentConfirmationModal
        isOpen={deleteConfirmationModal.isOpen}
        onCancel={deleteConfirmationModal.onCancel}
        onConfirm={deleteConfirmationModal.onConfirm}
      />
    </div>
  );
}

export const MessageListChildren: FC<IMessageListChildrenProps> = ({
  filters,
  comment,
  replyData,
  showReplyBox,
  onInternalReply,
  onBottomReached,
  isReplyingComment,
  isReplyBtnLoading,
  onClose,
  listId,
}) => {
  const listRef = React.useRef<ListRef>(null);

  const onListScroll = (event: UIEvent<HTMLDivElement>) => {
    if (
      event.currentTarget.scrollHeight -
        (event.currentTarget.scrollTop + event.currentTarget.offsetHeight) <
      50
    ) {
      onBottomReached && onBottomReached();
    }
  };

  return (
    <List
      itemKey="id"
      ref={listRef}
      data={replyData.items}
      onScroll={onListScroll}
      className={s.bbMessageVirtualList}
    >
      {item => (
        <MessageListChild
          item={item}
          comment={comment}
          filters={filters}
          replyData={replyData}
          showReplyBox={showReplyBox}
          onInternalReply={onInternalReply}
          isReplyingComment={isReplyingComment}
          isReplyBtnLoading={isReplyBtnLoading}
          onClose={onClose}
          listId={listId}
        />
      )}
    </List>
  );
};
