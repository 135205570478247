import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./ReplyDeleteAlert.module.less";

export interface IDeleteReplyAlertProps {
  onCancel: () => void;
  onApprove: () => void;
  isDeleting?: boolean;
}

const DeleteReplyAlert: FC<IDeleteReplyAlertProps> = ({
  onCancel,
  onApprove,
  isDeleting = false,
}) => {
  const { t } = useTranslation();

  return (
    <div className={s.bbReplyDeleteAlert}>
      <p className={s.bbReplyDeleteAlertTitle}>{t("components:messageCardDeleteAlert:message")}</p>
      <span className={s.bbReplyDeleteAlertButtons}>
        <Button
          _size="sm"
          _type="secondary"
          onClick={onCancel}
          disabled={isDeleting}
          className={s.bbReplyDeleteAlertCancel}
        >
          {t("generic:cancel")}
        </Button>
        <Button _size="sm" _type="danger" onClick={onApprove} loading={isDeleting}>
          {t("generic:delete")}
        </Button>
      </span>
    </div>
  );
};

export default DeleteReplyAlert;
