import dayjs from "dayjs";
import fileDownload from "js-file-download";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ILine } from "@bbdevcrew/bb_ui_kit_fe";
import { getBreakdownChartBarColor } from "../../_common/BreakdownChart/helpers";

import { getCommentsCSVAction, resetCommentsCSVState } from "@store/comments/actions";
import {
  getCommentsCSVSelector,
  getCommentsCSVSelectorPending,
  getCommentsCSVSelectorSuccessful,
} from "@store/comments/selectors";

import palette from "@bbdevcrew/bb_ui_kit_fe/dist/theme/scheme.module.less";

import { extractDate } from "@utils/dates";
import { i18nextInstance } from "./../../../App";

import {
  IExplorerOverviewSentiments,
  IOverviewPerformanceItem,
  ISentimentTimeline,
} from "@store/dashboard/types";
import { ITimelineDataSet } from "@store/search/types";
import { ICommentsCSVPayload } from "@store/comments/types";
import { IAreaChartData, IColorData, IPieChartUnit } from "./SentimentDetails.type";
import { IFilters } from "@store/filters/types";

export const CHART_WIDTH = 280;
export const CHART_HEIGHT = 280;

export const DEFAULT_COMMENT_EXPORT_LIMIT = 5000;

export const tooltipDelay = process.env.TOOLTIP_DELAY ? parseFloat(process.env.TOOLTIP_DELAY) : 1.2;

export const sentimentColorData = {
  positive: palette.colorPositive,
  negative: palette.colorNegative,
  neutral: palette.colorNeutral,
  user_tags: palette.colorUserTags,
  not_applicable: palette.colorNotApplicable,
  total: palette.colorBlue900,
} as IColorData;

export const stripedBackground = palette.colorStriped;

export const getTimelineTooltipLabel = (timelineDataSet: ITimelineDataSet) => {
  const date = extractDate(timelineDataSet.date);
  const end_date = extractDate(timelineDataSet.end_date);
  const hour = timelineDataSet.hour ? ` - ${timelineDataSet.hour}` : "";
  const end_hour = timelineDataSet.end_hour ? ` - ${timelineDataSet.end_hour}` : "";

  return `${date}${hour} / ${end_date}${end_hour}`;
};

export const normalizeTimelineData = (searchData: ISentimentTimeline) =>
  searchData.date_histogram?.datasets.map((timelineDataSet: ITimelineDataSet) => {
    return {
      ...timelineDataSet,
      date: `${timelineDataSet.date || ""} ${timelineDataSet.hour || ""}`,
      tooltipLabel: getTimelineTooltipLabel(timelineDataSet),
    };
  });

export const normalizeSentimentData = (searchData: ISentimentTimeline) => {
  return (searchData.items || []).map(({ id, name, count }) => {
    return {
      value: count,
      rawName: id,
      color: sentimentColorData[id as keyof IColorData],
      name: name,
      tooltipString: i18nextInstance.t(`components:searchPanel:cardTooltips:${id}`),
    };
  }) as IPieChartUnit[];
};

export const normalizePlatformData = (
  platformData: IExplorerOverviewSentiments[] | IOverviewPerformanceItem[],
) => {
  return platformData.map((item, index) => {
    return {
      value: item.count,
      rawName: item.id,
      name: item.name,
      color: getBreakdownChartBarColor(item.id, index),
      change_percentage: item.change_percentage,
      percentage_of_total: item.percentage_of_total,
    };
  });
};

export const getLines = () =>
  [
    {
      dataKey: "negative",
      stroke: sentimentColorData["negative"],
      name: i18nextInstance.t("components:searchPanel:charts:negative"),
      tooltipString: i18nextInstance.t("components:searchPanel:areaChartTooltips:negative"),
    },
    {
      dataKey: "positive",
      stroke: sentimentColorData["positive"],
      name: i18nextInstance.t("components:searchPanel:charts:positive"),
      tooltipString: i18nextInstance.t("components:searchPanel:areaChartTooltips:positive"),
    },
    {
      dataKey: "user_tags",
      stroke: sentimentColorData["user_tags"],
      name: i18nextInstance.t("components:searchPanel:charts:user_tags"),
      tooltipString: i18nextInstance.t("components:searchPanel:areaChartTooltips:user_tags"),
    },
    {
      dataKey: "neutral",
      stroke: sentimentColorData["neutral"],
      name: i18nextInstance.t("components:searchPanel:charts:neutral"),
      tooltipString: i18nextInstance.t("components:searchPanel:areaChartTooltips:neutral"),
    },
    {
      dataKey: "not_applicable",
      stroke: sentimentColorData["not_applicable"],
      customLegendBackground: stripedBackground,
      strokeDasharray: "5 5",
      name: i18nextInstance.t("components:searchPanel:charts:not_applicable"),
    },
  ] as ILine[];

export const getCSVData = (data: IAreaChartData[]) => {
  return data.map(item => {
    return {
      name: `${item.date.replace(",", " ").trim()}`,
      total: item.total.toString(),
      negative: item.negative.toString(),
      positive: item.positive.toString(),
      user_tags: item.user_tags.toString(),
      neutral: item.neutral.toString(),
    };
  });
};

export const getCSVTableHeaders = () => {
  return [
    i18nextInstance.t("components:searchPanel:columnTitles:date"),
    i18nextInstance.t("components:searchPanel:columnTitles:totalComments"),
    i18nextInstance.t("components:searchPanel:columnTitles:negativeComments"),
    i18nextInstance.t("components:searchPanel:columnTitles:positiveComments"),
    i18nextInstance.t("components:searchPanel:columnTitles:userTags"),
    i18nextInstance.t("components:searchPanel:columnTitles:neutralComments"),
  ];
};

export const useDownloadCommentsCSV = (filters: IFilters, sortValue: string) => {
  const dispatch = useDispatch();
  const [commentsDownloadModalOpen, setCommentsDownloadModalOpen] = useState(false);

  const commentsCSVFetched = useSelector(getCommentsCSVSelectorSuccessful);
  const commentsCSVPending = useSelector(getCommentsCSVSelectorPending);
  const commentsCSV = useSelector(getCommentsCSVSelector);

  const getCommentsCSV = useCallback(
    (data: ICommentsCSVPayload) => dispatch(getCommentsCSVAction(data)),
    [dispatch],
  );

  const triggerCommentsDownload = useCallback(() => {
    setCommentsDownloadModalOpen(true);

    getCommentsCSV({
      filters,
      limit: DEFAULT_COMMENT_EXPORT_LIMIT,
      sort: sortValue && sortValue === "asc" ? "asc" : "desc",
    });
  }, [filters, sortValue, getCommentsCSV]);

  useEffect(() => {
    if (commentsCSV && commentsCSVFetched) {
      const now = dayjs();
      fileDownload(
        commentsCSV,
        `comments_${now.format("YYYY-MM-DD")}_${now.format("hh_mm_A")}.csv`,
      );
    }
  }, [commentsCSV, commentsCSVFetched, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetCommentsCSVState());
    };
  }, [dispatch]);

  return {
    triggerCommentsDownload,
    commentsDownloadModalOpen,
    closeModal() {
      setCommentsDownloadModalOpen(false);
    },
    commentsCSVPending,
    commentsCSVFetched,
  };
};
