import { CommentListType, DEFAULT_COMMENT_LIST_KEY } from "@store/comments/types";
import { RootState } from "../";

export const fromReplies = (state: RootState) => state.replies;

export const repliesSelector = (state: RootState) => fromReplies(state).data;

export const fetchingRepliesSelector = (state: RootState) => fromReplies(state).fetchingReplies;
export const fetchedRepliesSelector = (state: RootState) => fromReplies(state).fetchedReplies;
export const getRepliesSuccessfulSelector = (state: RootState) => fromReplies(state).fetchedReplies;
export const getRepliesFailedSelector = (state: RootState) => fromReplies(state).fetchedRepliesFail;
export const repliesClearedSelector = (state: RootState) => fromReplies(state).clearReplies;

export const fetchingRepliesAttachmentsSelector = (state: RootState) =>
  fromReplies(state).fetchingRepliesAttachments;
export const fetchedRepliesAttachmentsSelector = (state: RootState) =>
  fromReplies(state).fetchedRepliesAttachments;

export const fetchingThreadRepliesSelector = (state: RootState) =>
  fromReplies(state).fetchingThreadReplies;
export const getThreadRepliesSuccessfulSelector = (state: RootState) =>
  fromReplies(state).fetchedThreadReplies;
export const getThreadRepliesFailedSelector = (state: RootState) =>
  fromReplies(state).fetchedThreadRepliesFail;
export const replyingThreadCommentSelector = (state: RootState) =>
  fromReplies(state).replyingThreadComment;
export const repliedThreadCommentSelector = (state: RootState) =>
  fromReplies(state).repliedThreadComment;

export const getSavedRepliesSelector = (state: RootState) => fromReplies(state).savedReplies;

export const getAISuggestedReplySelector = (state: RootState) =>
  fromReplies(state).aiSuggestedReply;

export const fetchAiSuggestedReplySelector = (state: RootState) =>
  fromReplies(state).fetchAiSuggestedReply;

export const searchedSavedRepliesSelector = (state: RootState) =>
  fromReplies(state).searchedSavedReplies;

export const repliesCursorSelector = (state: RootState) => fromReplies(state).data?.cursor;
export const postingReplySelector = (state: RootState) => fromReplies(state).postingCommentReply;
export const postedReplySelector = (state: RootState) => fromReplies(state).postedCommentReply;
export const postReplyRegionIsNotAllowedFailureSelector = (state: RootState) =>
  fromReplies(state).postCommentReplyRegionIsNotAllowedFailure;

export const deletingBrandReplySelector = (state: RootState) =>
  fromReplies(state)?.deletingBrandReply;
export const deletedReplyIdSelector = (state: RootState) => fromReplies(state)?.deletedReplyId;

export const getCreateSavedReplyCustomTagsSelector = (state: RootState) =>
  fromReplies(state).savedReplyCustomTags;

export const getSavedReplyBBTagsSelector = (state: RootState) =>
  fromReplies(state).savedReplyBbTags;

export const getReplyCommentsSelector = (
  state: RootState,
  conversationListId: CommentListType = DEFAULT_COMMENT_LIST_KEY,
) => {
  const commentsState = fromReplies(state);

  if (state.replies.cache && Object.keys(state.replies.cache).length > 0) {
    return commentsState.lists?.[conversationListId]?.comments.map(id => state.replies.cache[id]);
  } else return [];
};

export const fetchingReplyCommentsSelector = (
  state: RootState,
  conversationListId: CommentListType = DEFAULT_COMMENT_LIST_KEY,
) => {
  return fromReplies(state).lists?.[conversationListId]?.fetchingComments;
};

export const replyCommentsNavigationIdSelector = (
  state: RootState,
  conversationListId: CommentListType = DEFAULT_COMMENT_LIST_KEY,
) => {
  return fromReplies(state).lists?.[conversationListId]?.navigation_id;
};

export const replyCommentsCursorSelector = (
  state: RootState,
  conversationListId: CommentListType = DEFAULT_COMMENT_LIST_KEY,
) => {
  return fromReplies(state).lists?.[conversationListId]?.cursor;
};

export const getDraftReplySelector = (commentId: string) => (state: RootState) => {
  const draftReplies = fromReplies(state).draftReplies;
  return draftReplies[commentId] || "";
};

export const replyImageSelector = (state: RootState) => fromReplies(state).replyImage;
export const uploadingReplyImageSelector = (state: RootState) =>
  fromReplies(state).uploadingReplyImage;
export const uploadedReplyImageSelector = (state: RootState) =>
  fromReplies(state).uploadedReplyImage;
export const uploadingReplyImageFailedSelector = (state: RootState) =>
  fromReplies(state).uploadingReplyImageFailed;
export const uploadingReplyImageFailedMessageSelector = (state: RootState) =>
  fromReplies(state).uploadingReplyImageFailedMessage;

export const accountPostsSelector = (state: RootState) => fromReplies(state).accountPosts;
export const gettingAccountPostsSelector = (state: RootState) =>
  fromReplies(state).gettingAccountPosts;
export const gotAccountPostsSelector = (state: RootState) => fromReplies(state).gotAccountPosts;
export const gettingAccountPostsFailedSelector = (state: RootState) =>
  fromReplies(state).gettingAccountPostsFailed;
export const gettingAccountPostsFailedMessageSelector = (state: RootState) =>
  fromReplies(state).gettingAccountPostsFailedMessage;
export const gettingAccountPostsHasMoreSelector = (state: RootState) =>
  fromReplies(state).gettingAccountPostsHasMore;
export const gettingAccountPostsPageSelector = (state: RootState) =>
  fromReplies(state).gettingAccountPostsPage;
