import React from "react";

import MessageTypePieChart from "@components/insights/overview/messageTypePieChart/MessageTypePieChart"; // eslint-disable-line max-len
import MessageTypeDetailTable from "@components/insights/overview/messageTypeDetailTable/MessageTypeDetailTable"; // eslint-disable-line max-len
import UserLanguages from "@components/insights/overview/userLanguages/UserLanguages";
import TopInfluencers from "@components/insights/overview/topInfluencers/TopInfluencers";

import s from "./MessageTypes.module.less";

import { IMessageTypesProps } from "./MessageTypes.types";

export const MessageTypes: React.FC<IMessageTypesProps> = ({
  messageTypes,
  languages,
  influencers,
  isFetching,
  filters,
}) => {
  return (
    <div className={s.bbMessageTypes}>
      <div className={s.bbMessageTypesRow}>
        <UserLanguages data={languages || []} loading={isFetching} filterData={filters} />
        <TopInfluencers data={influencers || []} loading={isFetching} filterData={filters} />
      </div>
      <div className={s.bbMessageTypesRow}>
        <MessageTypePieChart
          data={messageTypes?.messageTypeDistribution}
          loading={isFetching}
          filterData={filters}
        />
        <MessageTypeDetailTable data={messageTypes?.messageTypeDetail || []} loading={isFetching} />
      </div>
    </div>
  );
};
