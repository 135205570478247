import React from "react";
import { useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Form } from "antd";
import { Input, Button, TextareaNew } from "@bbdevcrew/bb_ui_kit_fe";
import BrandBastionTags from "../../CommentCard/brandBastionTags/BBTags";
import CustomTagsList from "../../CommentCard/customTags/CustomTagsList";
import CommentTagsList from "../../CommentCard/commentTags/CommentTagsList";
import CustomTagsDropdown from "../../CommentCard/customTags/CustomTagsDropdown";

import {
  postSavedReplyAction,
  postReplyBBTagAction,
  deleteReplyBBTagAction,
} from "@store/replies/actions";
import {
  getSavedReplyBBTagsSelector,
  getCreateSavedReplyCustomTagsSelector,
} from "@store/replies/selectors";
import { canManageBBTagsSelector } from "@store/me/selectors";
import { deleteCustomTagFromReplyAction } from "@store/customTags/actions";

import s from "./SavedReplies.module.less";

import { ViewChangeFunc } from "../common/modal/types";
import { ICommentTag } from "@store/comments/types";
import { SavedReplyMessageMaxLimit, SavedReplyNameMaxLimit } from "./helper";
import { SavedReplyPayloadType } from "@store/replies/types";

import { ChevronLeftIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface ISavedReplyCreateProps {
  onViewChange: ViewChangeFunc;
}

const SavedReplyCreate: React.FC<ISavedReplyCreateProps> = ({ onViewChange }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const bbTags = useSelector(getSavedReplyBBTagsSelector);
  const canManageBBTag = useSelector(canManageBBTagsSelector);
  const customTags = useSelector(getCreateSavedReplyCustomTagsSelector);

  const [form] = useForm();

  const onFinish = (values: SavedReplyPayloadType) => {
    const { name, message } = values;

    dispatch(
      postSavedReplyAction({
        name: name.trim(),
        message: message.trim(),
        custom_tags: customTags.map(tag => tag.id).filter(id => id !== undefined) as string[],
        tags: bbTags.map(tag => tag.id),
      }),
    );
    form.resetFields();
    onViewChange("list");
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    onViewChange("list");
  };

  const handleDeleteCustomTag = (tagId?: string) => {
    tagId && dispatch(deleteCustomTagFromReplyAction(tagId));
  };

  const handleDeleteBBTag = (tagId: string) => {
    dispatch(deleteReplyBBTagAction(tagId));
  };

  const handleAddBBTag = ({ id, label, category }: ICommentTag) => {
    dispatch(postReplyBBTagAction({ id, label, category }));
  };

  return (
    <>
      <div className={s.bbSavedReplyHeader}>
        <Button _type="link" className={s.bbSavedReplyHeaderBack} onClick={handleCancel}>
          <ChevronLeftIcon />
        </Button>
        <div className={s.bbSavedReplyHeaderTitle}>
          {t("components:reply:savedReplies:create:title")}
        </div>
      </div>
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        className={s.bbSavedReplyCreateForm}
        onFinish={onFinish}
      >
        <Form.Item
          name="name"
          label={t("components:reply:savedReplies:create:nameInputlabel")}
          rules={[
            {
              required: true,
              message: t("components:reply:savedReplies:create:nameInputError"),
            },
            {
              max: SavedReplyNameMaxLimit,
              message: t("components:reply:savedReplies:create:messageCharLimit", {
                amount: SavedReplyNameMaxLimit,
              }),
            },
            {
              // If contains only whitespaces
              pattern: /^(?!\s*$).+/,
              validateTrigger: "onSubmit",
              message: t("components:reply:savedReplies:create:nameInputError"),
            },
          ]}
        >
          <Input
            placeholder={t("components:reply:savedReplies:create:namePlaceholder")}
            className={s.bbSavedReplyCreateFormInput}
            _size="lg"
          />
        </Form.Item>
        <Form.Item
          name="message"
          label={t("components:reply:savedReplies:create:messageInputlabel")}
          rules={[
            {
              required: true,
              message: t("components:reply:savedReplies:create:messageInputError"),
            },
            {
              max: SavedReplyMessageMaxLimit,
              message: t("components:reply:savedReplies:create:messageCharLimit", {
                amount: SavedReplyMessageMaxLimit,
              }),
            },
            {
              // If contains only whitespaces
              pattern: /^(?!\s*$).+/,
              validateTrigger: "onSubmit",
              message: t("components:reply:savedReplies:create:messageInputError"),
            },
          ]}
        >
          <TextareaNew className={s.bbSavedReplyCreateFormInputMessage} _size="lg" />
        </Form.Item>
        <div className={s.bbSavedRepliesTags} data-stonly-target="app-global__saved-replies--tags">
          <CommentTagsList tags={bbTags} isReply className={s.bbCommentTagsList} />
          <CustomTagsList customTagsByUser={customTags} onDeleteTag={handleDeleteCustomTag} />
          <CustomTagsDropdown
            customTagsByUser={customTags}
            customTagsAutomated={[]}
            hasTags={!!customTags.length}
          />
          {canManageBBTag && (
            <BrandBastionTags
              tags={bbTags}
              onAddTag={handleAddBBTag}
              onDeleteTag={handleDeleteBBTag}
            />
          )}
        </div>
        <Form.Item>
          <div className={s.bbSavedReplyCreateFormActions}>
            <Button _type="link" onClick={handleCancel}>
              {t("generic:cancel")}
            </Button>
            <Button _type="primary" type="submit">
              {t("generic:submit")}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};

export default SavedReplyCreate;
