import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "@bbdevcrew/bb_ui_kit_fe";
import Markdown from "react-markdown";

import { meSelector } from "@store/me/selectors";

import { sendThreadMessageAction } from "@store/aiInsights/actions";

import s from "./AIInsightsModal.module.less";

import { getInitials } from "../MessageCard/helpers";
import { AI_ICONS_ACTIONS_MAP } from "./AIInsightsModal.helpers";
import { IAIInsightsModalMessageProps } from "./AIInsightsModal.types";

import { CheckIcon } from "@bbdevcrew/bb_ui_kit_fe";
import AstraImg from "@assets/astra.png";

const cx = classNames.bind(s);

export const AIInsightsModalMessage: React.FC<IAIInsightsModalMessageProps> = ({
  message,
  isOwned = false,
  actions = [],
  id: aiModalId,
  loading,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const me = useSelector(meSelector);

  const sendMessages = useCallback(
    (id, msg) =>
      dispatch(
        sendThreadMessageAction({
          id,
          message: msg,
        }),
      ),
    [dispatch],
  );

  return (
    <>
      {!!message && (
        <div
          className={cx(s.bbAIModalMessage, {
            [s.bbAIModalRightMessage]: isOwned,
          })}
        >
          {isOwned ? (
            <div className={s.bbAIModalMessageUser}>
              {getInitials(`${me?.first_name} ${me?.last_name}`)}
            </div>
          ) : (
            <img src={AstraImg} className={s.bbAIModalMessagesListAvatar} />
          )}

          <div className={s.bbAIModalMessageContent}>
            {!isOwned && <span>{t("components:aiInsights:astra")}</span>}
            <div className={s.bbAIModalMessageText}>
              <Markdown>{message}</Markdown>
            </div>
          </div>
        </div>
      )}
      <div
        className={cx(s.bbAIModalActions, {
          [s.bbAIModalActionsActive]: actions.some(action => action.is_selected),
        })}
      >
        {actions.map(action => (
          <Button
            _type={action.is_selected ? "secondary" : "primary"}
            className={cx(s.bbAIModalActionsButton, {
              [s.bbAIModalActionsButtonDisabled]: action.is_selected,
            })}
            key={action.id}
            disabled={loading}
            onClick={!action.is_selected ? () => sendMessages(aiModalId, action.type) : undefined}
          >
            {action.is_selected ? (
              <CheckIcon />
            ) : (
              AI_ICONS_ACTIONS_MAP[action.type as keyof typeof AI_ICONS_ACTIONS_MAP] || null
            )}
            {action.label}
          </Button>
        ))}
      </div>
    </>
  );
};
